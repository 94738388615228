window.addEventListener("load", function () {
  // Initialize Intercom settings
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "dnj0e9s5",
    base_site_url: frappe.urllib.get_base_url(),
    name: frappe.user_info
      ? frappe.user_info().name + " - " + window.location.hostname
      : window.location.hostname, // Full name
    email: frappe.user_info ? frappe.user_info().email : "Guest", // Email address
  };

  // condition required to skip intercom when side panel opens
  if(window.self === window.top) {
    // Load Intercom and check system service status
    loadIntercom();
    checkSystemServiceStatus();
  }
});

/**
 * Load Intercom widget script and initialize Intercom.
 */
function loadIntercom() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/dnj0e9s5";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}

/**
 * Check the system service status and update Intercom settings.
 */
function checkSystemServiceStatus() {
  frappe.call({
    method: 'execution.execution.intercom.get_system_service_status',
    callback: (r) => {
      let systemServiceStatus = r.message.status ? 'Up' : 'Down';
      Intercom('update', {
        'system_service_status': systemServiceStatus
      });
    }
  });
}
